'use client';

import { RTEClient } from '@pickleballinc/rte-service-client';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { RTELog, RTEMatchInfo, RTEMatchLogs } from '@/contexts/rte/types';

// Define the context and the type for the provider's value
interface RTEContextValue {
	logs: RTEMatchLogs[];
	matches: RTEMatchInfo[];
	updateOrAddMatch: (match: RTEMatchInfo, matchId: string | undefined) => void;
}

// Create the context with default values
const RTEContext = createContext<RTEContextValue | undefined>(undefined);

// Custom hook to access the RTEContext
export const useRTE = () => {
	const context = useContext(RTEContext);
	if (!context) {
		throw new Error('useRTE must be used within a RTEProvider');
	}
	return context;
};

interface RTEProviderProps {
	matchUuids?: string[];
	userToken: string;
	ipAddress: string;
	children: React.ReactNode;
}

export const RTEProvider: React.FC<RTEProviderProps> = ({ matchUuids, userToken, ipAddress, children }) => {
	const [logs, setLogs] = useState<RTEMatchLogs[]>([]);
	const [matches, setMatches] = useState<RTEMatchInfo[]>([]);

	// Function to add a log in the logs state (DB restriction: Same log will only come once through the RTE)
	const addLog = useCallback((messageLog: RTELog) => {
		const matchUuid = messageLog.match_uuid;

		setLogs((prevLogs) => {
			const matchIndexToUpdate = prevLogs.findIndex((match) => match.match_uuid === matchUuid);

			if (matchIndexToUpdate !== -1) {
				const logsCopy = structuredClone(prevLogs);

				const matchToUpdate = logsCopy[matchIndexToUpdate];

				if (matchToUpdate) {
					matchToUpdate.logs.push(structuredClone(messageLog));
					return logsCopy;
				}
			} else {
				return [
					{
						match_uuid: matchUuid,
						logs: [structuredClone(messageLog)]
					}
				];
			}

			return prevLogs;
		});
	}, []);

	// Function to add/update a match in the matches state
	const updateOrAddMatch = useCallback((messageMatch: RTEMatchInfo, matchId: string | undefined) => {
		const matchUuid = messageMatch.matchUuid || matchId;

		setMatches((prevMatches) => {
			const matchIndexToUpdate = prevMatches.findIndex((match) => match.matchUuid === matchUuid);

			if (matchIndexToUpdate !== -1) {
				const matchesCopy = [...prevMatches];
				const matchToUpdate = matchesCopy[matchIndexToUpdate];

				matchesCopy[matchIndexToUpdate] = { ...matchToUpdate, ...messageMatch };

				return matchesCopy;
			} else {
				return [...prevMatches, { ...messageMatch }];
			}
		});
	}, []);

	useEffect(() => {
		if (process.env.NEXT_PUBLIC_RTE_ENABLED !== 'true') {
			return;
		}

		// Initialize the RTEClient and subscribe to the "message" event
		const client = new RTEClient({
			endpoint: 'live-scoring',
			matches: matchUuids || [], // Subscribe to matches with uuids matchUuids, or all matches if matchUuids is undefined
			devMode: process.env.NEXT_PUBLIC_APP_ENV !== 'prod', // Toggle as needed for your environment
			withLogs: matchUuids && matchUuids.length === 1 ? true : false,
			headers: {
				'PB-USER-IP': ipAddress,
				'PB-USER-TOKEN': userToken
			}
		});

		// Logic for results/match/:id page
		if (matchUuids && matchUuids.length === 1) {
			matchUuids.map((matchUuid) => {
				client.addEventListener(matchUuid, (evt: any) => {
					try {
						const matchDataObj = JSON.parse(evt.data);

						// Only exists for logs
						if (matchDataObj.log_index !== undefined) {
							addLog(matchDataObj as RTELog);
						} else {
							updateOrAddMatch(matchDataObj as RTEMatchInfo, evt.id);
						}
					} catch (e) {
						console.error(`RTE - ${matchUuid} - Failed to parse match data. Match uuid: ${evt.id}. Match data: ${evt.data}`);
					}
				});

				const refLogsCustomMessage = `reflog_${matchUuid}`;

				client.addEventListener(refLogsCustomMessage, (evt: any) => {
					try {
						const logDataObj: RTELog = JSON.parse(evt.data);

						addLog(logDataObj);
					} catch (e) {
						console.error(`RTE - ${refLogsCustomMessage} - Failed to parse log data. Event id: ${evt.id}. Log data: ${evt.data}`);
					}
				});
			});
		} else {
			// Handle the "message" event and add the message to the state
			client.addEventListener('message', (evt: any) => {
				try {
					const matchDataObj: RTEMatchInfo = JSON.parse(evt.data);

					updateOrAddMatch(matchDataObj, evt.id);
				} catch (e) {
					console.error(`RTE - message - Failed to parse match data. Match uuid: ${evt.id}. Match data: ${evt.data}`);
				}
			});
		}

		// Handle any errors
		client.addEventListener('error', () => {
			// console.error('RTE - Stopping live match updates because of unexpected error:', error);

			client.reset(matchUuids || []);
		});

		// Clean up the client on component unmount
		return () => {
			client.close();
		};
	}, []);

	return <RTEContext.Provider value={{ logs, matches, updateOrAddMatch }}>{children}</RTEContext.Provider>;
};
