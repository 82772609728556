export type AdType = '679331' | '679332' | '679340' | '688399' | '688401';

export const AD_SIZES_HASH = {
	'679331': {
		size: '300x250',
		width: 300,
		height: 250
	}, // small card ad
	'679332': {
		size: '300x600',
		width: 300,
		height: 600
	}, // long card ad
	'679340': {
		size: '1280x130',
		width: 1280,
		height: 130
	}, // wide banner ad
	'688399': {
		size: '300x250',
		width: 300,
		height: 250
	}, // tourney card ad
	'688401': {
		size: '300x250',
		width: 300,
		height: 250
	}, // league card ad
	'721101': {
		size: '0x0',
		width: 0,
		height: 0
	}, // modal ad
	'788107': {
		size: '0x0',
		width: 0,
		height: 0
	}, // modal ad redesigned
	'809175': {
		size: '0x0',
		width: 0,
		height: 0
	}
};
export interface ModalPlacementInterface {
	banner_id: string;
	width: string;
	height: string;
	alt_text: string;
	accompanied_html: string;
	target: string;
	tracking_pixel: string;
	body: string;
	redirect_url: string;
	refresh_url: string;
	rct: string;
	rcb: string;
	refresh_time: string;
	placement_id: string;
	user_frequency_views: string;
	user_frequency_start: string;
	user_frequency_expiry: string;
	viewable_url: string;
	eligible_url: string;
	is_redirectable: boolean;
	image_url: string;
	accupixel_url: string;
}
export interface PlacementInterface {
	banner_id: string;
	width: string;
	height: string;
	alt_text: string;
	accompanied_html: string;
	target: string;
	tracking_pixel: string;
	body: string;
	redirect_url: string;
	refresh_url: string;
	rct: string;
	rcb: string;
	refresh_time: string;
	placement_id: string;
	user_frequency_views: string;
	user_frequency_start: string;
	user_frequency_expiry: string;
	viewable_url: string;
	eligible_url: string;
	is_redirectable: boolean;
	image_url: string;
	impressions_remaining: number;
	has_quota: boolean;
	accupixel_url: string;
}
